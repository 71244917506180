import { SearchSelect } from 'components/SearchSelect/SearchSelect';
import { TeamMemberCellRendererProps } from 'components/Table/types';
import React, { FC } from 'react';
import { isEmpty } from 'lodash';
import styles from './TeamMemberCellRenderer.module.scss';
import { SearchedMember } from 'components/SearchedMember/SearchedMember';
import { Icon } from 'components/Icon/Icon';
import { useHandleNewMemberAddition } from './hooks/useHandleNewMemberAddition';

export const TeamMemberCellRenderer: FC<TeamMemberCellRendererProps> = ({ row, columnDef }) => {
    const { isLoading, usersList, handleInviteNewMember } = useHandleNewMemberAddition(row, columnDef.onClick);
    const handleClearInput = () => columnDef.onChange!('', row.id);

    return !row.newMember ? (
        <span className={styles.teamMember}>
            <span className={styles.userPhoto}>
                <Icon type="user" />
            </span>
            <span>
                {row.name} {row.surname}
            </span>
        </span>
    ) : (
        <div className={styles.teamMember}>
            <SearchSelect
                testId="searchSelect"
                isLoading={isLoading}
                name="team-member-input"
                placeholder="Invite by email address..."
                value={row.invitationEmail}
                isEmpty={isEmpty(usersList)}
                onChange={(e) => columnDef.onChange!(e.target.value, row.id)}
                onClear={handleClearInput}
                handleNewMember={handleInviteNewMember}
                error={row.error}
            >
                {usersList.map((user) => (
                    <SearchedMember
                        testId={user.id}
                        email={user.email}
                        key={user.id}
                        onClick={() => {
                            handleInviteNewMember(user);
                        }}
                    />
                ))}
            </SearchSelect>
        </div>
    );
};
