import React, { FC } from 'react';
import IconMap from './IconMap';
import { IconProps } from './types';
import cn from 'classnames';
import styles from './Icon.module.scss';
import ReactTooltip from 'react-tooltip';

export const Icon: FC<IconProps> = ({ type, className, onClick, inline, tooltipInfo, testId, ...rest }) => {
    const IconComponent = IconMap[type.replace(/\s/, '').toLowerCase()];
    if (!IconComponent) {
        return null;
    }
    const wrapperClassName = cn(
        inline ? styles.iconWrapperInline : styles.iconWrapper,
        className,
        tooltipInfo ? styles.pointer : '',
    );
    const onIconClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        if (!onClick) {
            return;
        }
        onClick(event);
    };
    return tooltipInfo ? (
        <>
            <span
                data-testid={`icon-${testId}`}
                data-tip={tooltipInfo.text}
                data-for={tooltipInfo.id}
                className={wrapperClassName}
                onClick={onIconClick}
                {...rest}
            >
                <IconComponent />
            </span>
            <ReactTooltip border multiline effect="solid" id={tooltipInfo.id} />
        </>
    ) : (
        <span data-testid={`icon-${testId}`} className={wrapperClassName} onClick={onIconClick} {...rest}>
            <IconComponent />
        </span>
    );
};
