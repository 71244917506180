import React, { FC, useContext, useEffect, useState } from 'react';
import { TeamAccountsList } from 'components/TeamAccountsList/TeamAccountsList';

import { PageHeading } from 'components/PageHeading/PageHeading';
import { PageSubtitle } from 'components/PageSubtitle/PageSubtitle';

import { TeamsProps } from './types';

import { useTeamAccountsListData } from 'components/TeamAccountsList/hooks/useTeamAccountsListData';
import { NoDataBlock } from './components/NoDataBlock/NoDataBlock';

import styles from './Teams.module.scss';
import commonStyles from '../CommonStyles.module.scss';

import { LoaderComponent } from 'components/Loader/Loader';
import { createBtnText } from 'components/TeamAccountsList/constants';
import { Button } from 'components/Button/Button';
import { styleTypes } from 'shared/constants';
import { AccountTeamsTab } from 'components/AccountTeamsTab/AccountTeamsTab';
import { DEFAULT_DELAY } from 'components/Table/components/TableRowRenderer/components/AccountTeamExpandedRow/constants';
import { Card } from 'components/Card/Card';
import { DataSourcesIdsListType, PeriodValues, TeamAccountsTableItemsType } from 'shared/types';
import { MembersToCreateListType, MembersToDeleteListType } from 'components/TeamMembersList/hooks/types';
import { v4 as uuidv4 } from 'uuid';
import { usePrepareTeamListData } from 'components/TeamAccountsList/hooks/usePrepareTeamListData';
import { TeamsContext } from 'pages/Teams/providers/TeamsProvider';
import { oneTeamSubtitleText, teamsHeaderText, teamsSubtitleText } from './constants';

import { TeamInvitationAcceptedModal } from 'components/TeamInvitationAcceptedModal/TeamInvitationAcceptedModal';
import { TeamInvitationDeclinedModal } from 'components/TeamInvitationDeclinedModal/TeamInvitationDeclinedModal';
import { useInvitationDeclinedModal } from 'pages/InvitationPage/hooks/useInvitationDeclinedModal';
import { TeamsFilterContext } from 'shared/providers/TeamsFilterProvider/TeamsFilterProvider';
import { DateFilterContext } from 'shared/providers/DateFilterProvider/DateFilterProvider';

export const Teams: FC<TeamsProps> = ({ testId }) => {
    const emptyRow = {
        rowId: uuidv4(),
        name: '',
        isCreated: true,
        members: [],
        sources: [],
        collapsed: false,
        id: uuidv4(),
    };
    const { updateSelectedTeam } = useContext(TeamsFilterContext);
    const { setSelectedPeriod } = useContext(DateFilterContext);

    useEffect(() => {
        updateSelectedTeam('');
        setSelectedPeriod!(PeriodValues.allTime);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { editTeam, isLoading, teamList, deleteTeam, createTeam, leaveTeam, refetch } = useTeamAccountsListData();
    const { teamsService, dataSourcesDropdownData, teamOwnerInfo } = usePrepareTeamListData(teamList);
    const {
        handleAcceptInvitationFromModal,
        handleAcceptedModalClose,
        handleDeclinedModalClose,
        invitationAcceptedModalState,
        invitationDeclinedModalState,
        loading: invitationLoading,
    } = useInvitationDeclinedModal(refetch);
    const { isSomeItemCollapsed } = useContext(TeamsContext);

    const [isFormOpened, setIsFormOpened] = useState(false);
    const openForm = () => {
        setIsFormOpened(true);
    };

    const handleCreateTeam = (
        teamName: string,
        membersToCreateList: MembersToCreateListType,
        membersToDeleteList: MembersToDeleteListType,
        dataSourcesToCreateList: DataSourcesIdsListType,
        dataSourcesToDeleteList: DataSourcesIdsListType,
    ) => {
        createTeam(
            teamName,
            membersToCreateList,
            membersToDeleteList,
            dataSourcesToCreateList,
            dataSourcesToDeleteList,
        );
        setIsFormOpened(false);
    };

    const onCloseHandler = () => {
        setTimeout(() => {
            setIsFormOpened(false);
        }, DEFAULT_DELAY);
    };

    return (
        <>
            {isLoading || invitationLoading ? (
                <div className={styles.loader}>
                    <LoaderComponent />
                </div>
            ) : (
                <div className={commonStyles['main-container']}>
                    {teamList.length ? (
                        <>
                            <PageHeading className={styles.heading}>{teamsHeaderText}</PageHeading>
                            <PageSubtitle>
                                {teamList.length} {teamList.length === 1 ? oneTeamSubtitleText : teamsSubtitleText}
                            </PageSubtitle>
                            <TeamAccountsList
                                teamOwnerInfo={teamOwnerInfo!}
                                teams={teamsService as TeamAccountsTableItemsType}
                                dataSourcesDropdownData={dataSourcesDropdownData}
                                onDeleteTeam={deleteTeam}
                                editTeam={editTeam}
                                leaveTeam={leaveTeam}
                                testId={testId}
                                canEditTeam={!isFormOpened}
                            />

                            {!isFormOpened && (
                                <Button
                                    style={styleTypes.primary}
                                    className={styles.createTeamButton}
                                    isLink={false}
                                    onClick={openForm}
                                    isDisabled={isSomeItemCollapsed}
                                >
                                    {createBtnText}
                                </Button>
                            )}
                        </>
                    ) : (
                        <NoDataBlock openForm={openForm} isFormOpened={isFormOpened} />
                    )}
                    {isFormOpened && (
                        <Card className={styles.container}>
                            <AccountTeamsTab
                                testId="accountTeamsTabCreateMode"
                                teamOwnerInfo={teamOwnerInfo!}
                                dataSourcesDropdownData={dataSourcesDropdownData}
                                onClose={onCloseHandler}
                                editMode={false}
                                onSaveChanges={handleCreateTeam}
                                onDeleteTeam={deleteTeam}
                                row={emptyRow}
                            />
                        </Card>
                    )}
                    <TeamInvitationAcceptedModal
                        isOpen={invitationAcceptedModalState}
                        onClose={handleAcceptedModalClose}
                    />
                    <TeamInvitationDeclinedModal
                        isOpen={invitationDeclinedModalState}
                        onClose={handleDeclinedModalClose}
                        onAccept={handleAcceptInvitationFromModal}
                    />
                </div>
            )}
        </>
    );
};
