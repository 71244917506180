import React from 'react';

import cn from 'classnames';

import { Icon } from 'components/Icon/Icon';
import { InputProps } from './types';

import styles from './Input.module.scss';

export class Input extends React.Component<InputProps> {
    render() {
        const {
            className,
            id,
            label,
            subLabel,
            labelIcon,
            name,
            type,
            value,
            icon,
            iconTwo,
            placeholder,
            onChange,
            onIconClick,
            onIconTwoClick,
            deleteSpacesOnInput,
            validation,
            children,
            isDisabled = false,
            isAutocomplete = true,
            required = false,
            ...rest
        } = this.props;
        const inputClassName = cn(styles.input, { [styles.withTwoIcons]: iconTwo });

        return (
            <div className={className} {...rest}>
                <div className={styles.labelBox}>
                    <label className={styles.label} htmlFor={id}>
                        {label}
                        {labelIcon && <Icon type={labelIcon} className={styles.labelIcon} data-testid="labelIcon" />}
                    </label>
                    {subLabel && (
                        <span data-testid="subLabel" className={styles.sublabel}>
                            {subLabel}
                        </span>
                    )}
                </div>

                <div className={styles.inputBox}>
                    <input
                        disabled={isDisabled}
                        data-testid={`subInput-${id}`}
                        className={inputClassName}
                        type={type}
                        name={name}
                        value={value}
                        id={id}
                        placeholder={placeholder}
                        onChange={(event) => onChange && onChange(event)}
                        onKeyDown={(event) => deleteSpacesOnInput && event.key === ' ' && event.preventDefault()}
                        ref={validation}
                        required={required}
                        {...(!isAutocomplete ? { autoComplete: 'new-password' } : {})}
                    />

                    {icon && (
                        <Icon
                            data-testid="icon"
                            type={icon}
                            className={styles.icon}
                            onClick={() => onIconClick && onIconClick()}
                        />
                    )}

                    {iconTwo && (
                        <Icon
                            data-testid="iconTwo"
                            type={iconTwo}
                            className={styles.iconTwo}
                            onClick={() => onIconTwoClick && onIconTwoClick()}
                        />
                    )}

                    {children}
                </div>
            </div>
        );
    }
}
