/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState } from 'react';

import { Button } from 'components/Button/Button';
import { ModalDialog } from 'components/ModalDialog/ModalDialog';

import { styleTypes } from 'shared/constants';
import {
    leaveTeamButtonText,
    cancelModalLeaveTeamBtnText,
    okModalLeaveTeamBtnText,
    titleModalLeaveTeamBtn,
    descriptionModalLeaveTeamText,
} from '../../constants';

import { ViewerEditorButtonsBlockProps } from './types';

import styles from '../../AccountTeamsTabButtons.module.scss';

export const ViewerEditorButtonsBlock: FC<ViewerEditorButtonsBlockProps> = (props) => {
    const { testId, teamId, onLeaveTeam } = props;

    const [modalState, setModalState] = useState(false);
    const handleOk = () => onLeaveTeam(teamId);
    const handleCancel = () => setModalState(false);
    const handleOpenModal = () => {
        setModalState(true);
    };

    return (
        <>
            <ModalDialog
                isOpen={modalState}
                toggleModal={() => setModalState(false)}
                onOk={handleOk}
                onCancel={handleCancel}
                onOkBtnText={okModalLeaveTeamBtnText}
                onCancelBtnText={cancelModalLeaveTeamBtnText}
                title={titleModalLeaveTeamBtn}
                description={descriptionModalLeaveTeamText}
            />
            <Button
                data-testid={`${testId}.leaveTeamBtn`}
                style={styleTypes.secondary}
                className={styles.btn}
                isLink={false}
                onClick={handleOpenModal}
            >
                {leaveTeamButtonText}
            </Button>
        </>
    );
};
