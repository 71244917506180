import React, { FC } from 'react';

import { Button } from 'components/Button/Button';
import { OwnerButtonsBlock } from './components/OwnerButtonsBlock/OwnerButtonsBlock';
import { ViewerEditorButtonsBlock } from './components/ViewerEditorButtonsBlock/ViewerEditorButtonsBlock';

import { styleTypes } from 'shared/constants';
import { applyChangesButtonText, createTeamButtonText, closeButtonText } from './constants';

import { AccountTeamsTabButtonsProps } from './types';

import styles from './AccountTeamsTabButtons.module.scss';

export const AccountTeamsTabButtons: FC<AccountTeamsTabButtonsProps> = (props) => {
    const { testId, saveChanges, close, isNewTeam, onDeleteTeam, onLeaveTeam, teamId, editMode, isViewer, isEditor } =
        props;

    return (
        <div className={styles.container}>
            {editMode && !isViewer && !isEditor && (
                <OwnerButtonsBlock testId={`${testId}-ownerButtonBlock`} onDeleteTeam={onDeleteTeam} teamId={teamId} />
            )}
            {editMode && (isViewer || isEditor) && (
                <ViewerEditorButtonsBlock testId={testId} teamId={teamId} onLeaveTeam={onLeaveTeam} />
            )}
            <div className={styles.rightBlock}>
                <Button
                    data-testid={`${testId}.closeBtn`}
                    style={styleTypes.secondary}
                    className={styles.btn}
                    isLink={false}
                    onClick={close}
                >
                    {closeButtonText}
                </Button>
                {!isViewer && (
                    <Button
                        data-testid={`${testId}.saveBtn`}
                        style={styleTypes.primary}
                        className={styles.btn}
                        isLink={false}
                        onClick={saveChanges}
                    >
                        {isNewTeam ? createTeamButtonText : applyChangesButtonText}
                    </Button>
                )}
            </div>
        </div>
    );
};
