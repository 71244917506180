import React, { FC } from 'react';
import cn from 'classnames';

import { CloseButtonProps } from './types';
import { Icon } from 'components/Icon/Icon';

import styles from './CloseButton.module.scss';

export const CloseButton: FC<CloseButtonProps> = ({ className, onCancel, testId }) => {
    return <Icon type="close" testId={testId} className={cn(styles.button, className)} onClick={onCancel} />;
};
