import React, { useState, useEffect, FC } from 'react';
import { SearchSelectProps } from './types';
import { Icon } from 'components/Icon/Icon';
import styles from './SearchSelect.module.scss';
import { enterBtn, loadingText } from './constants';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';

export const SearchSelect: FC<SearchSelectProps> = (props) => {
    const {
        testId,
        value,
        name,
        placeholder,
        onChange,
        isEmpty,
        children,
        onClear,
        error,
        isLoading,
        handleNewMember,
    } = props;
    const [focused, setFocused] = useState<boolean>(false);
    const onBlurDelay = (): any =>
        setTimeout(() => {
            setFocused(false);
        }, 500);

    const handleKeyPress = (event: any) => {
        if (event.key === enterBtn) {
            handleNewMember({ email: value, id: uuidv4(), newMember: true });
        }
    };

    useEffect(() => {
        return () => {
            clearTimeout(onBlurDelay as any);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showUsersList = !isLoading && !error;

    return (
        <>
            <div
                data-testid={`${props.testId}.searchSelectBox`}
                className={classNames(styles.searchSelectBox, { [styles.withError]: error })}
            >
                <input
                    autoComplete="off"
                    data-testid={`${testId}.searchSelect`}
                    value={value}
                    name={name}
                    placeholder={placeholder}
                    onChange={(event) => onChange(event)}
                    className={styles.searchSelect}
                    onFocus={() => setFocused(true)}
                    onBlur={onBlurDelay}
                    onKeyDown={(e) => handleKeyPress(e)}
                />
                <div
                    data-testid={`${testId}.searchSelectList`}
                    className={styles.searchSelectList}
                    style={{
                        display: (!isEmpty && focused) || isLoading ? 'block' : 'none',
                    }}
                >
                    {isLoading && !error && <>{loadingText}</>}
                    {showUsersList && children}
                </div>
                {error && <span className={styles.searchSelectError}>{error}</span>}
                <>
                    <button
                        data-testid={`${testId}.searchSelectIcon`}
                        className={styles.searchSelectIcon}
                        onClick={onClear}
                    >
                        <Icon data-testid="searchSelect" type="clear" inline />
                    </button>
                </>
            </div>
        </>
    );
};
